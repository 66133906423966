<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-toolbar dense dark color="primary">
              <v-toolbar-title><h4 class="font-weight-light">OFFICER'S INFOMATION</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-col md="4" cols="12"></v-col>
              <v-row>
                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="last_name"
                    label="Last Name"
                    dense
                    outlined
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>
                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="first_name"
                    label="First Name"
                    dense
                    outlined
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>
                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="middle_name"
                    label="Middle Name"
                    dense
                    outlined
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-select
                    v-model="position"
                    dense
                    outlined
                    label="Position"
                    :items="[ 'CHAPEL DIRECTOR', 'REGIONAL CHAPEL MANAGER']"
                    :rules="rules.combobox_rule"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert color="warning" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ alert_message }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn color="primary" class="me-3 mt-4" @click="save_caller" v-if="!saving">
                Save
                changes
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-text class="mt-4">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-uppercase">ID</th>
              <th class="text-center text-uppercase">Name</th>
              <th class="text-center text-uppercase">Position</th>
              <th class="text-center text-uppercase">View</th>
              <th class="text-center text-uppercase">Assign</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in list_of_officers" :key="item.id">
              <td>{{ item.id }}</td>
              <td class="text-center">
                {{ item.last_name+', '+item.first_name+' '+item.middle_name }}
              </td>
              <td class="text-center">
                {{ item.position}}
              </td>
              <td class="text-center">
                <div>
                  <v-icon
                    v-if="!is_deleting"
                    class="mr-2"
                    color="success"
                    @click="view_branches(item)"
                  >
                    {{icons.mdiFileFind}}
                  </v-icon>
                  <v-progress-circular color="info" indeterminate
                                       v-else></v-progress-circular>
                </div>
              </td>
              <td class="text-center">
                <div>
                  <v-icon
                    v-if="!is_deleting"
                    class="mr-2"
                    color="info"
                    @click="assign_branches(item)"
                  >
                    {{icons.mdiClipboardArrowLeft}}
                  </v-icon>
                  <v-progress-circular color="info" indeterminate
                                       v-else></v-progress-circular>
                </div>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="can_view_branches" persistent max-width="500px">
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LIST OF BRANCHES UNDER {{title}}</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center text-uppercase">Branch</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in list_of_branches" :key="item.id">
            <td>
              {{ item.branch_code}}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-btn color="error" @click="can_view_branches = false"> Close</v-btn>
    </v-dialog>

    <v-dialog v-model="can_assign_branches" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">ASSIGN BRANCHES UNDER {{title}}</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-combobox
          class="mt-6 mx-2"
          :append-icon="icons.mdiAccountSearchOutline"
          v-model="search"
          @keyup="searching($event.target.value)"
          @change="get_search_items_info"
          :items="search_items"
          placeholder="Search by Branch Code"
          ref="memberCombobox"
          item-value="id"
          item-text="branch_code"
          dense
          outlined
        ></v-combobox>
        <v-col cols="12" v-if="can_save">
          <v-btn color="primary" class="me-3" @click="save_branch" v-if="!saving">
            Save
            changes
          </v-btn>
          <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                               v-else></v-progress-circular>
        </v-col>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-center text-uppercase">Branch</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in list_of_branches" :key="item.id">
              <td>
                {{ item.branch_code}}
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <v-btn color="error" @click="can_assign_branches = false"> Close</v-btn>
    </v-dialog>


    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>

  </div>
</template>

<script>
  import {
    mdiAlertOutline,
    mdiCloudUploadOutline,
    mdiAccountSearchOutline,
    mdiPencilBoxOutline,
    mdiFileFind,
    mdiClipboardArrowLeft,
  } from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      cropped: require('@/assets/images/avatars/2.png'),

      saving: false,
      alert: false,
      alert_message: '',

      last_name: '',
      first_name: '',
      middle_name: '',
      position: '',
      title: '',
      search: '',
      selected_branch: {},
      selected_officer: {},


      list_of_officers: [],
      list_of_branches: [],
      search_items: [],
      is_deleting: false,

      can_view_branches: false,
      can_assign_branches: false,
      can_save: false,
      key: 0,
    }
  }

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        show: false,
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiAccountSearchOutline,
          mdiPencilBoxOutline,
          mdiFileFind,
          mdiClipboardArrowLeft,
        },
      }
    },
    mounted() {
      this.list_of_officerses()
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['branch', 'branch_id', 'year', 'month_start', 'month_end', 'month_of', 'user_id']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('officers', ['register_officers', 'get_officers_active', 'add_branch_under_officer']),
      ...mapActions('branch_information', ['search_branch',]),
      list_of_officerses() {
        this.list_of_branches = []
        this.get_officers_active()
          .then(response => {
            this.list_of_officers = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      view_branches(item) {
        this.title = item.last_name + ', ' + item.first_name + ' ' + item.middle_name
        this.list_of_branches = item.rcm_branch.length > 0 ? item.rcm_branch : item.cd_branch
        this.can_view_branches = true
        this.key++
      },
      assign_branches(item) {
        this.selected_officer = item
        this.list_of_branches = item.rcm_branch.length > 0 ? item.rcm_branch : item.cd_branch
        this.title = item.last_name + ', ' + item.first_name + ' ' + item.middle_name
        this.can_assign_branches = true
        this.key++
      },
      searching(value) {
        this.can_save = false
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.search_branch({
            branch_code: value
          })
            .then(response => {
              this.search_items = response.data
            })
        }, 500)

      },
      get_search_items_info() {
        if (this.search_items.length > 0) {
          var index = this.search_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.search.id)
          if (index != -1) {
            this.selected_branch = this.search_items[index]
            this.can_save = true
          }
        }
      },
      reset() {
        Object.assign(this.$data, initialState())
        this.list_of_officerses()
      },
      save_caller() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('last_name', this.last_name.toUpperCase())
          data.append('first_name', this.first_name.toUpperCase())
          data.append('middle_name', this.middle_name.toUpperCase())
          data.append('position', this.position)
          this.register_officers(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              } else {
                this.reset()
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
            })
            .catch(error => {
              console.log(error)
              this.alert = true
              this.alert_message = error.response.data.message
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      save_branch() {
        this.saving = true
        this.alert = false
        const data = new FormData()
        data.append('branch_id', this.selected_branch.id)
        data.append('rcm_id', this.selected_officer.position === 'REGIONAL CHAPEL MANAGER' ? this.selected_officer.id : '')
        data.append('cd_id', this.selected_officer.position === 'CHAPEL DIRECTOR' ? this.selected_officer.id : '')
        this.add_branch_under_officer(data)
          .then(response => {
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            } else {
              this.reset()
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
          })
          .catch(error => {
            console.log(error)
            this.alert = true
            this.alert_message = error.response.data.message
            this.saving = false
          })
      },
    },
  }
</script>
